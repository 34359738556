import * as React from "react";
import { graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LFullService,
  LIconic,
  LThe,
  LCanvas,
  LAtoll,
  CBtnList02,
} from "../../components/_index";
import { size } from "lodash";
import { setLang } from "../../utils/set-lang";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: "SEARCH BY BRAND",
            ja: "ブランドから探す",
          },
          img: {
            src: "/assets/images/brand/kv.png",
          },
          imgSp: {
            src: "/assets/images/brand/kv__sp.png",
          },
        }}
      />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: "ブランドから探す",
          },
        }}
      />
      <div className="l_sect02">
        <LWrap>
          <p className="c_sectLead">
            洗練された、心地よい空間とホスピタリティ。
            <br />
            宿泊、レストラン、宴会機能が揃うフルサービス型ホテルと、宿泊主体型ホテルのラインナップを持ち、その街ならではのおもてなしを提供します。
          </p>
        </LWrap>
      </div>
      <LFullService />
      <LIconic showNum={3} />
      <LThe showNum={3} />
      <LCanvas showNum={3} />
      <LAtoll />
      <div className="l_sect">
        <LWrap>
          <CBtnList02
            data={[
              {
                label: {
                  ja: "エリアから探す",
                  en: "SEARCH BY DESTINATION",
                },
                link: {
                  href: "/location/",
                },
              },
              {
                label: {
                  ja: "過ごし方から探す",
                  en: "SEARCH BY EXPERIENCE",
                },
                link: {
                  href: "/experience/",
                },
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
